import React from "react";
import ReactDOM from "react-dom";
import "./style/index.css";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./style/theme";
import { initializeGA } from "routes/tracker";
import { isLocalhost } from "utils/dev";
import AppProviders from "contexts";

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<AppProviders>
			<App />
		</AppProviders>
	</ThemeProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
if (!isLocalhost) initializeGA();
