import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Link,
  Card,
  Typography,
  CardContent,
  Grid,
  CardActions,
  Button,
} from '@material-ui/core';
import chunk from 'lodash/chunk';
import { useAuth } from 'contexts/auth-context';

let processes = [
  { name: 'Thermovapor Compression' },
  { name: 'Multi-Effect Distillation' },
  { name: 'Humidification Dehumidification' },
  { name: 'Reverse Osmosis' },
  { name: 'Electrodialysis' },
  { name: 'Membrane Capacitive Deionization' },
];
const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(3),
    width: '100%',
    // display: "flex",
    // justifyContent: "center"
  },
  card: {
    maxWidth: 800,
    margin: 'auto',
  },
  introImage: {
    margin: 'auto',
    height: 100,
    float: 'right',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
function Home(props) {
  const classes = useStyles();
  const { user } = useAuth();
  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Welcome!
          </Typography>
          <img
            className={classes.introImage}
            src={process.env.PUBLIC_URL + '/images/logo_512.png'}
            alt=""
          />
          <Typography variant="body1" gutterBottom>
            The Desal Application is a web-based simulation engine designed to
            facilitate decision making for people involved in the water
            desalination industry.
          </Typography>

          <Grid container direction="row">
            <Grid item xs={12}>
              <Typography variant="body1">
                Desal can simulate the following desalination processes:
              </Typography>
            </Grid>
            {chunk(processes, Math.ceil(processes.length / 2)).map((arr, i) => (
              <Grid item key={i}>
                <ul>
                  {arr.map(({ name }, j) => (
                    <li key={`${i}:${j}`}>
                      <Typography>{name}</Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
          <Typography variant="body1" gutterBottom>
            The Desal Application is part of the project “A Zero Liquid
            Discharge Water Desalination Process Using
            Humidification-Dehumidification in a Thermally-Actuated Transport
            reactor”, funded by the US Department of Energy (DE-EE0008402).
          </Typography>
          <Typography>
            For a detailed description of the DESAL application, program
            documentation and source code please refer to the associated
            Doctoral Dissertation.
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          {user && (
            <Button
              variant="outlined"
              href={`${process.env.PUBLIC_URL}/files/Dissertation Proposal - Sebastian Romo.pdf`}
            >
              Documentation
            </Button>
          )}
          {user && (
            <Button
              size="large"
              component={RouterLink}
              to="/models"
              color="primary"
              variant="contained"
            >
              Start
            </Button>
          )}
          {!user && (
            <Button
              className={classes.button}
              component={RouterLink}
              to="/user/login"
              color="inherit"
              variant="outlined"
            >
              Signup or Login to Start usign Desal
            </Button>
          )}
        </CardActions>
        <CardContent>
          <Typography variant="caption" gutterBottom>
            &copy;{new Date().getFullYear()}{' '}
            <Link href="https://city.umd.edu/">City@UMD</Link> &
            <Link href="https://umd.edu/">
              The University of Maryland, College Park
            </Link>
          </Typography>
          <br />
          <Typography variant="caption" gutterBottom>
            Created By:
            <Link color="inherit" href="https://mattise.dev/">
              {' '}
              Nicholas Mattise
            </Link>{' '}
            and{' '}
            <Link
              color="inherit"
              href="https://ec.linkedin.com/in/sebastian-romo-48bb2998/es-es"
            >
              Sebastian Romo
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
export default Home;
