import React from "react";
// import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

const formatNumber = (n) => Number(n).toLocaleString();
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		overflowX: "auto",
		// paddingBottom: theme.spacing(2)
	},
	table: {
		// minWidth: 650
	},
}));

function BasicTable(props) {
	const classes = useStyles();
	const { headers = [], rows = [], dense = false, rpp = 5 } = props;
	const [page, setPage] = React.useState(0);
	const [rowsPerPage] = React.useState(rpp);

	function handleChangePage(event, newPage) {
		setPage(newPage);
	}
	return (
		<div className={classes.root}>
			<Table className={classes.table} size={dense ? "small" : "medium"}>
				<TableHead>
					<TableRow>
						{headers.map((column, i) => {
							return (
								<TableCell
									key={`column-${column.key}`}
									align={column.align ? column.align : "left"}
								>
									{column.value}
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((row, i) => (
							<TableRow key={i}>
								{headers.map((col, j) => (
									<TableCell
										key={`${row.id}-${j}`}
										align={col.align ? col.align : "left"}
									>
										{isNaN(row[col.key])
											? row[col.key]
											: formatNumber(row[col.key])}
									</TableCell>
								))}
							</TableRow>
						))}
				</TableBody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[5]}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				backIconButtonProps={{
					"aria-label": "Previous Page",
				}}
				nextIconButtonProps={{
					"aria-label": "Next Page",
				}}
				onChangePage={handleChangePage}
			/>
		</div>
	);
}
export default BasicTable;
