import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitIcon from "@material-ui/icons/ExitToApp";
import { useAuth } from "contexts/auth-context";
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	barImage: { height: 48, paddingRight: theme.spacing(1) },
	button: { margin: theme.spacing(1) },
}));

export default function ButtonAppBar() {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const isMenuOpen = Boolean(anchorEl);
	const { user, logout } = useAuth();
	function handleProfileMenuOpen(event) {
		setAnchorEl(event.currentTarget);
	}
	function handleMenuClose() {
		setAnchorEl(null);
	}
	function logoutUser() {
		handleMenuClose();
		logout();

		history.push("/");
	}

	const menuId = "primary-search-account-menu";
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			{/* <MenuItem
				onClick={handleMenuClose}
				component={RouterLink}
				to="/user/account"
			>
				<ListItemIcon>
					<AccountCircle />
				</ListItemIcon>
				My Account
			</MenuItem> */}
			{user?.admin && (
				<MenuItem onClick={handleMenuClose} component={RouterLink} to="/admin">
					<ListItemIcon>
						<AccountCircle />
					</ListItemIcon>
					Admin
				</MenuItem>
			)}
			<MenuItem onClick={logoutUser}>
				<ListItemIcon>
					<ExitIcon />
				</ListItemIcon>
				Logout
			</MenuItem>
		</Menu>
	);
	return (
		<div>
			<AppBar position="static">
				<Toolbar>
					<RouterLink to={user ? "/models" : "/"}>
						<img
							className={classes.barImage}
							src={process.env.PUBLIC_URL + "/images/logo_192.png"}
							alt=""
						/>
					</RouterLink>
					<Link
						color="inherit"
						component={RouterLink}
						to={user ? "/models" : "/"}
						variant="h6"
						className={classes.title}
					>
						Desal
					</Link>
					{user && (
						<Button
							className={classes.button}
							component={RouterLink}
							to="/about"
							color="inherit"
						>
							About
						</Button>
					)}
					{user && (
						<Button
							className={classes.button}
							component={RouterLink}
							to={"/feedback"}
							color="inherit"
						>
							Feedback
						</Button>
					)}
					{user ? (
						<IconButton
							edge="end"
							aria-label="account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							onClick={handleProfileMenuOpen}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
					) : (
						<>
							<Button
								className={classes.button}
								component={RouterLink}
								to="/user/signin"
								color="inherit"
							>
								Sign In
							</Button>
							<Button
								className={classes.button}
								component={RouterLink}
								to="/user/signup"
								color="inherit"
								variant="outlined"
							>
								Sign Up
							</Button>
						</>
					)}
				</Toolbar>
			</AppBar>
			{renderMenu}
		</div>
	);
}
