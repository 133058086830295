import React from "react";
import { homepage } from "../../package.json";
import { AuthProvider } from "./auth-context";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryConfigProvider } from "react-query";

const queryConfig = {
	shared: {
		suspense: true,
	},
	queries: {
		useErrorBoundary: true,
		refetchOnWindowFocus: false,
		retry(failureCount, error) {
			if (error.status === 404) return false;
			else if (failureCount < 2) return true;
			else return false;
		},
	},
};

let basename = "";
if (homepage) {
	const fullUrl = new URL(homepage);
	basename = fullUrl.pathname.endsWith("/")
		? fullUrl.pathname.slice(0, fullUrl.pathname.length - 1)
		: fullUrl.pathname;
}
function AppProviders({ children }) {
	return (
		<ReactQueryConfigProvider config={queryConfig}>
			<Router basename={basename}>
				<AuthProvider>{children}</AuthProvider>
			</Router>
		</ReactQueryConfigProvider>
	);
}
export default AppProviders;
