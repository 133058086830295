import React from "react";
import { Typography } from "@material-ui/core";

export default function Error({ message }) {
	return (
		<div>
			Error...
			<Typography>{message}</Typography>
		</div>
	);
}
