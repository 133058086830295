import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles, Typography, IconButton, Button } from "@material-ui/core";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import DeleteIcon from "@material-ui/icons/Delete";
import BasicPaginationTable from "components/tables/BasicPagination.table";
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		maxWidth: 600,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginLeft: "auto",
		marginRight: "auto",
	},
	dropzone: {
		padding: theme.spacing(2),
		textAlign: "center",
		border: `2px dashed ${theme.palette.primary[500]}`,
		borderRadius: theme.shape.borderRadius,
		minHeight: 50,
		minWidth: 250,
		backgroundColor: theme.palette.grey[100],
	},
	preview: {
		paddingTop: theme.spacing(1),
	},
}));
const usePreviewStyles = makeStyles((theme) => ({
	thumb: {
		display: "inline-flex",
		borderRadius: 2,
		border: "1px solid #eaeaea",
		marginBottom: 8,
		marginRight: 8,
		width: 100,
		height: 100,
		padding: 4,
		boxSizing: "border-box",
	},
	thumbInner: {
		display: "flex",
		minWidth: 0,
		overflow: "hidden",
	},
	img: {
		display: "block",
		width: "auto",
		height: "100%",
	},
	icon: { display: "block", width: "auto", height: "100%" },
}));

function FilePreview(props) {
	const classes = usePreviewStyles();
	const { files = [] } = props;
	return (
		<>
			{files.map((file) => (
				<div className={classes.thumb} key={file.name}>
					<div className={classes.thumbInner}>
						{file.isImage ? (
							<img src={file.preview} className={classes.img} />
						) : (
							<FileIcon classname={classes.icon} />
						)}
					</div>
				</div>
			))}
		</>
	);
}
const headers = [
	{ key: "filename", value: "Filename" },
	{ key: "size", value: "Size (kB)", align: "right" },
	{ key: "delete", value: "Delete", align: "center" },
];
function FileUpload(props) {
	const classes = useStyles();
	const { files = [], onUpload = () => {}, preview, inputProps } = props;
	const [localFiles, setLocalFiles] = useState(files);
	const onDrop = useCallback(
		(acceptedFiles) => {
			// Do something with the files
			setLocalFiles(
				acceptedFiles.map((file) => {
					if (file.type.split("/")[0] === "image") {
						return Object.assign(file, {
							preview: URL.createObjectURL(file),
							isImage: true,
						});
					} else {
						return Object.assign(file, {
							icon: "insertDriveFile",
							isImage: false,
						});
					}
				})
			);
			onUpload(acceptedFiles);
		},
		[onUpload]
	);
	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		noClick: true,
		noKeyboard: true,
		maxSize: 1.2e7,
	});
	const removeFile = (file) => {
		let fs = localFiles.filter((f) => f.path !== file.path);
		setLocalFiles(fs);
		onUpload(fs);
	};

	return (
		<div className={classes.root}>
			<div {...getRootProps({ className: classes.dropzone })}>
				<input {...getInputProps()} {...inputProps} />
				<Typography variant="subtitle1">
					Drag and drop some files here, or{" "}
				</Typography>
				<Button variant="contained" onClick={open}>
					click to select files
				</Button>
				<div className={classes.preview}>
					{localFiles.length > 0 && !preview && (
						<div>
							<Typography variant="subtitle2" align="center">
								{localFiles.length} Files Uploaded
							</Typography>
						</div>
					)}
					{localFiles.length > 0 && preview === "table" && (
						<BasicPaginationTable
							dense
							headers={headers}
							rows={localFiles.map((file) => ({
								filename: file.name,
								size: Math.floor(file.size / 1000),
								type: file.type,
								delete: (
									<IconButton onClick={() => removeFile(file)}>
										<DeleteIcon />
									</IconButton>
								),
							}))}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
export default FileUpload;
