import { createMuiTheme } from "@material-ui/core/styles";
import { blueGrey, red } from "@material-ui/core/colors";

// http://mcg.mbitson.com/#!?mcgpalette0=%2320517e&mcgpalette1=%233f51b5&themename=mcgtheme
// https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=20517E&primary.text.color=ffffff&secondary.color=ECEFF1
export const defaultColor = {
	50: "#e4eaf0",
	100: "#bccbd8",
	200: "#90a8bf",
	300: "#6385a5",
	400: "#416b91",
	500: "#20517e",
	600: "#1c4a76",
	700: "#18406b",
	800: "#133761",
	900: "#0b274e",
	A100: "#85b1ff",
	A200: "#5290ff",
	A400: "#1f70ff",
	A700: "#0560ff",
	contrastDefaultColor: "light",
};

let theme = createMuiTheme({
	palette: {
		primary: defaultColor,
		secondary: blueGrey,
		error: red,
	},
	status: {
		danger: "orange",
	},
});
theme = {
	...theme,
	overrides: {
		MuiButton: {
			label: {
				textTransform: "none",
			},
			root: {
				textTransform: "none",
			},
		},
		MuiTableCell: {
			head: {
				fontWeight: 600,
			},
		},
	},
};
export default theme;
