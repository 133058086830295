import React from "react";
import { queryCache } from "react-query";
import * as auth from "api/auth-provider";
import { client } from "api/client";
import { useAsync } from "utils/useAsync";
import Loading from "components/status/Loading";
import Error from "components/status/Error";

const defaultNotifications = [];

async function bootstrapAppData() {
	let user = null;
	const token = await auth.getToken();
	if (token) {
		const data = await client("bootstrap", { token });
		user = data.user;
		user = {
			...user,
			notifications: defaultNotifications,
		};
	}

	return user;
}

const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";

function AuthProvider(props) {
	const {
		data: user,
		status,
		error,
		isLoading,
		isIdle,
		isError,
		isSuccess,
		run,
		setData,
	} = useAsync();

	React.useEffect(() => {
		const appDataPromise = bootstrapAppData();
		run(appDataPromise);
	}, [run]);

	// AUTHENTICATION
	const login = React.useCallback(
		(form) => auth.login(form).then((user) => setData(user)),
		[setData]
	);
	const register = React.useCallback(
		(form) => auth.register(form).then((user) => setData(user)),
		[setData]
	);

	const logout = React.useCallback(() => {
		auth.logout();
		queryCache.clear();
		setData(null);
	}, [setData]);

	// USER SETTINGS
	const updateUser = React.useCallback(
		(changes) => {
			return setData(Object.assign(user, changes));
		},
		[setData]
	);
	const clearAllNotifications = React.useCallback(() => {
		return setData(Object.assign(user, { notifications: [] }));
	}, [setData]);
	const value = React.useMemo(
		() => ({
			user,
			login,
			logout,
			register,
			updateUser,
			clearAllNotifications,
		}),
		[user, login, logout, register, updateUser, clearAllNotifications]
	);

	if (isLoading || isIdle) {
		return <Loading />;
	}

	if (isError) {
		return <Error error={error} />;
	}

	if (isSuccess) {
		return <AuthContext.Provider value={value} {...props} />;
	}

	throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
	const context = React.useContext(AuthContext);
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`);
	}
	return context;
}

function useClient() {
	const { user } = useAuth();
	const token = user?.token;
	return React.useCallback(
		(endpoint, config) => client(endpoint, { ...config, token }),
		[token]
	);
}

export { AuthProvider, useAuth, useClient };
