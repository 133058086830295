import React, { useEffect } from "react";
import "style/App.css";
import { Route, useHistory, Switch, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Loading from "components/status/Loading";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import NavBar from "components/Bar.nav";
import Home from "./Home";

import { logPageView } from "routes/tracker";

import { useAuth } from "contexts/auth-context";
import Feedback from "components/FeedBack";
const Auth = React.lazy(() => import("./Auth"));
const About = React.lazy(() => import("./About"));
const Process = React.lazy(() => import("./Processes/Root.Processes"));
const Admin = React.lazy(() => import("./Admin/Root.Admin"));
const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		background: theme.palette.grey[200],
	},
	appContent: {
		flex: "1 0 auto",
		display: "flex",
		flexDirection: "row",
		background: theme.palette.grey[200],
	},
	footer: {
		flexShrink: 0,
		display: "flex",
		justifyContent: "flex-end",
		marginLeft: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(2),
	},
}));
const Footer = () => {
	const classes = useStyles();
	return (
		<footer className={classes.footer}>
			<Typography variant="caption" gutterBottom>
				&copy;{new Date().getFullYear()}{" "}
				<Link color="inherit" href="https://city.umd.edu/">
					City@UMD
				</Link>{" "}
				&
				<Link color="inherit" href="https://umd.edu/">
					The University of Maryland, College Park
				</Link>
			</Typography>
		</footer>
	);
};

const PrivateRoute = ({ component: Component, user, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				user ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: "/user/login", state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};
function App() {
	const classes = useStyles();
	const history = useHistory();
	const { user } = useAuth();
	useEffect(() => {
		logPageView(history);
	}, [history]);
	return (
		<div className={classes.root}>
			<NavBar />
			<React.Suspense fallback={<Loading />}>
				<Switch>
					{/* Redirect oud homepage /desalination to Home */}
					<Redirect from="/desalination" to="/" />
					<div className={classes.appContent}>
						<Route exact path="/" component={Home} />
						<Route path="/user" component={Auth} />
						<Route path="/about" component={About} />
						<PrivateRoute user={user} path="/feedback" component={Feedback} />
						<PrivateRoute user={user} path="/admin" component={Admin} />
						<PrivateRoute user={user} path="/models" component={Process} />
					</div>

					<Route component={Home} />
				</Switch>
			</React.Suspense>
			<Footer />
		</div>
	);
}

export default App;
