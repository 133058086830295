import { client, localStorageKey } from "./apiClient";
function handleUserResponse(res) {
	const {
		user: { token, ...user },
	} = res;
	window.localStorage.setItem(localStorageKey, token);
	return user;
}

function getUser() {
	const token = getToken();
	if (!token) {
		return Promise.resolve(null);
	}
	return client(`user/me`).then((data) => data.user);
}

function login({ username, email, password }) {
	return client("user/login", { body: { username, email, password } })
		.then(handleUserResponse)
		.catch((err) => {
			return null;
		});
}

function register({ username, password, email }) {
	return client("user/register", { body: { email, username, password } })
		.then(handleUserResponse)
		.catch((err) => {
			return null;
		});
}
function resetPassword({ username, password, newPassword, email }) {
	return client("user/reset-password", {
		body: { email, username, password, newPassword },
	});
}

function requestPasswordReset({ username, email, pathname }) {
	let resetLink =
		window.location.href.split(pathname)[0] + "/user/reset-password";
	return client("user/request-reset", {
		body: { email, username, resetLink },
	})
		.then(handleUserResponse)
		.catch((err) => {
			return null;
		});
}

function getToken() {
	return window.localStorage.getItem(localStorageKey);
}

function isLoggedIn() {
	return Boolean(getToken());
}
async function sendFeedback({ email, comments, uploads = [] }) {
	let formData = new FormData();
	for (let i = 0, length1 = uploads.length; i < length1; i++) {
		formData.append("files", uploads[i], uploads[i].name);
	}
	formData.append("email", email);
	formData.append("comment", comments);

	return client(`user/feedback`, { formData });
}
export {
	login,
	register,
	getToken,
	getUser,
	isLoggedIn,
	sendFeedback,
	resetPassword,
	requestPasswordReset,
};
export { logout } from "./apiClient";
