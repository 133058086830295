import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { useAuth } from "contexts/auth-context";
import { sendFeedback } from "utils/authClient";
import SnackbarContentWrapper from "components/Alerts/SnackbarAlert.js";
import FileUpload from "components/FileUpload";
const useStyles = makeStyles((theme) => {
	return {
		root: { margin: theme.spacing(3), width: "100%", display: "flex" },
		container: {
			// minWidth: 400,
			[theme.breakpoints.up("md")]: {
				minWidth: 600,
			},
		},
		actions: { display: "flex", justifyContent: "space-between" },
		spacer: { flex: "1 1 100%" },
	};
});
const validateEmail = (mail) => {
	if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
		return true;
	}
	return false;
};
const validateForm = ({ email, comments }) => {
	return !validateEmail(email) || !comments || !comments.length > 0;
};
function Feedback(props) {
	const classes = useStyles();
	const { user } = useAuth();
	const [email, setEmail] = useState(user.email);
	const [comments, setComments] = useState("");
	const [uploads, setUploads] = useState();
	const [notification, setNotification] = useState({
		open: false,
	});
	console.log(user);
	function resetForm() {
		setComments();
		setUploads();
		setEmail(user.email);
	}
	const submitFeedback = React.useCallback(() => {
		sendFeedback({
			email,
			comments,
			uploads,
		})
			.then(() => {
				resetForm();
				setNotification({
					open: true,
					variant: "success",
					message: "Thanks for your feedback. Please await our response.",
				});
			})
			.catch((err) => {
				console.log(err);
				resetForm();
				setNotification({
					open: true,
					variant: "error",
					message:
						"There was an error when sending your feedback. Please try again later.",
					errorCode: 107,
				});
			});
	}, [resetForm]);

	let validEmail = validateEmail(email);
	let validFeedback = validateForm({ email, comments });
	console.log(email, comments, uploads);
	return (
		<div className={classes.root}>
			<Grid
				container
				direction="column"
				alignItems="center"
				className={classes.root}
			>
				<Grid item>
					<Card className={classes.container}>
						<form noValidate autoComplete="off">
							<CardHeader
								title={"Send Feedback"}
								subheader={"to Desalination Team"}
							/>
							<CardContent>
								<TextField
									fullWidth
									error={!validEmail}
									helperText={!validEmail ? "Invalid Email" : null}
									inputProps={{
										readOnly: true,
									}}
									defaultValue={email}
									type="email"
									id="email"
									label="User Email"
									margin="normal"
									variant="outlined"
									onChange={(event) => setEmail(event.target.value)}
								/>
								<TextField
									variant="outlined"
									id="multiline-comments"
									label="Comments"
									multiline
									rows={4}
									rowsMax="8"
									value={comments}
									onChange={(event) => setComments(event.target.value)}
									className={classes.textField}
									margin="normal"
									fullWidth
								/>
								<Typography
									style={{ marginTop: 8 }}
									gutterBottom
									variant="body2"
								>
									Optional file upload for screenshots or reports:
								</Typography>
								<FileUpload
									preview="table"
									files={uploads}
									onUpload={(f) => setUploads(f)}
									inputProps={{
										accept: "image/*",
										multiple: true,
										type: "file",
									}}
								/>
							</CardContent>
							<CardActions className={classes.actions}>
								<Button component={Link} to="/">
									Home
								</Button>
								<Button onClick={resetForm}>Reset</Button>
								<span className={classes.spacer}></span>
								<Button
									color="primary"
									variant="contained"
									disabled={validFeedback}
									onClick={submitFeedback}
								>
									Submit
								</Button>
							</CardActions>
						</form>
					</Card>
				</Grid>
			</Grid>
			{notification.open && (
				<SnackbarContentWrapper
					{...notification}
					onClose={() => setNotification({ open: false })}
				/>
			)}
		</div>
	);
}
Feedback.defaultProps = {
	user: {},
};

export default Feedback;
